<template>
  <div class="follow-us"
       v-if="isShow">
    <div class="follow-us-content">
      <div class="logo-white">
        <img :src="require('@/assets/logo-white.png')"
             alt="" />
      </div>
      <div class="follow-word">
        <div>关注华信合公众号</div>
        <div>进入终端控制系统</div>
      </div>
      <div class="follow-qrcode">
        <img :src="require('@/assets/qrcode.png')"
             alt="" />
      </div>
      <div class="qrcode-text">
        <div>长按识别二维码，一键添加关注</div>
      </div>
    </div>
    <img class="follow-us-bg"
         :src="require('@/assets/attention-bg.png')"
         alt="" />
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  name: "FollowUs",
  components: {},
  data () {
    return {
      isShow: false
    };
  },
  methods: {},
  computed: {},
  mounted () {
    Dialog.alert({
      message: '请先关注公众号',
    }).then(() => {
      this.isShow = true;
    });
  },
};
</script>
<style lang="scss" scoped>
.follow-us {
  width: 100%;
  height: 100%;
  position: relative;
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    text-align: center;
  }
  .logo-white {
    margin-top: 20px;
    margin-left: 10px;
    text-align: left;
    img {
      width: 129px;
      height: 68px;
    }
  }
  .follow-word {
    font-size: 29px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 62px;
    text-align: center;
  }
  .follow-qrcode {
    text-align: center;
    margin-top: 54px;
    img {
      width: 204px;
      height: 204px;
    }
  }
  .qrcode-text {
    margin-top: 16px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>